module.exports = { 
    monthly_free_minutes: '매월 무료 이용시간(분) 적립 (구독시 사용가능)',
    monthly_free_minutes_tooltip: '사용되지 않고 남은 무료이용시간은 다음달로 이월되며 구독 취소시까지 사용가능합니다. <br>매월 무료이용시간은 추가로 구매하신 이용시간에 우선하여 차감됩니다',
    additional_seat: '계정 공유 사용자',
    additional_seat_tooltip: '사용자를 초대하여 계정을 공유하고, 회의록, 텍스트 파일, 동영상 및 기능에 대한 접근 권한을 부여할 수 있습니다. 권한은 사용자 정의 가능합니다.',
    monthly_free_ai_credits: 'AI 포인트<a href="https://www.taption.com/ai-analysis/kr" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">세부</a>',
    monthly_free_ai_credits_tooltip: '구독 후 AI 포인트는 매달 자동으로 보충되며 언제든지 더 많은 포인트를 구매할 수 있습니다. <br>"고급 구독"은 월 최대 {0}포인트까지 보충할 수 있습니다.<br>"대규모 구독"은 월 최대 {1}포인트까지 보충할 수 있습니다.',
    monthly_free_ai_credits_desc: '매월 최대 {0}포인트를 보충하세요',
    minutes_price: '추가 이용시간 가격',
    minutes_price_tooltip: '추가로 구매하신 이용시간은 구독 취소 후에도 만료되지 않고 사용가능합니다.',
    max_file_length: '동영상 최대 길이',
    max_file_size: '업로드 가능한 최대 파일 크기',
    max_label_number: '최대 라벨 수',
    automated_transcription: '자동 받아쓰기',
    editing_platform_unlimited: '편집 플랫폼 사용에는 시간 제한이 없습니다',
    language_supported: '40+개 언어 지원',
    highlight_text: '텍스트 편집기',
    translate: '50+개 언어로 번역',
    translate_basic_tooltip: '번역 이용시 이용시간이 추가로 차감됩니다',
    translate_premium_tooltip: '번역 이용시 이용시간이 추가로 차감됩니다',
    translate_corp_tooltip: '번역 이용시 이용시간이 추가로 차감됩니다',
    personal_dictionary: 'AI 맞춤사전',
    allow_commercial: '상업적 이용 허용',
    mp4_multi_language_export_tooltip: '번역가능한 50+개 언어 중 선택하신 2개 언어를 자막으로 지원합니다. 편집 화면의 우측 상단의 번역 메뉴에서 번역을 추가할 수 있습니다.',
    mp4_multi_language_export: '다국어 자체삽입 자막 지원',
    mp4_multi_language_export_basic_tooltip: '무료 사용자는 동영상 추출시 추가 이용시간이 차감됩니다.',
    mp4_audio_export: '음성을 자막 및 원하는 배경이미지가 들어간 동영상으로 변환하세요',
    mp4_audio_export_tooltip: '음성 파일을 원하는 배경 이미지와 자체자막이 삽입된 동영상으로 변환하고 추출할 수 있습니다.',
    mp4_audio_export_basic_tooltip: '스탠다드 플랜 유저는 검은색 기본 배경의 동영상만 추출할 수 있습니다.',
    api_integration: '맞춤형 API 통합',
    api_integration_tooltip: '자세한 내용은 help@taption.com으로 문의하세요.',
    api_integration_basic_tooltip: '자세한 내용은 help@taption.com으로 문의하세요.',
    api_integration_premium_tooltip: '자세한 내용은 help@taption.com으로 문의하세요.',
    custom_payments: '맞춤형 결제 옵션',
    custom_payments_tooltip: '계좌이체/수표 결제 등 유연한 결제 방식 제공.자세한 내용은 help@taption.com으로 문의하세요.',
    advanced_training: '사용자를 위한 고급 교육',
    advanced_training_tooltip: '모든 직원의 적응을 돕는 데 도움이 필요하신가요? 저희가 도와드리겠습니다.',
    high_definition: '동영상 원본 화질 유지',
    high_definition_tooltip: '동영상을 원본 화질 그대로 편집하고 공유하세요',
    mp4_export: '자체자막 삽입 동영상 내보내기 무제한',
    mp4_export_basic_tooltip: '무료 사용자는 동영상 추출시 추가 이용시간이 차감됩니다.',
    editable_transcript_sharing: '받아쓰기 공유 및 공동편집',
    dark_mode: '다크 모드',
    video_editing: '텍스트를 편집하여 동영상 편집',
    video_cutting: '동영상 자르기 기능',
    multi_upload: '동영상 대량 업로드',
    invoice_company: '원하는 타이틀로 청구서 발행',
    invoice_taxid: '납세자 등록번호로 청구서 발행',
    share_minutes: '다중 사용자 액세스',
    share_minutes_tooltip: '하나의 계정에 다중 사용자를 추가하여 이용시간 및 받아쓰기를 공유하세요.',
    permission_users: '유저 권한 관리<a href="https://www.taption.com/collaborate/kr" target="__blank" style="color:#2FC59F;padding:0px 5px;font-size:12px;">세부</a>',
    permission_users_tooltip: '계정에 추가된 사용자들의 받아쓰기 번역, 업로드, 삭제, 내보내기 및 편집 권한을 제한 및 관리합니다.',
    max_char: '자막당 최대 문자 수',
    max_char_tooltip: '번역된 텍스트는 이 값에 따라 분할되며 각 세그먼트는 이 단어 수를 초과하지 않습니다.',
    direct_link: '다양한 비디오 및 오디오 파일 URL 링크 지원(업로드 필요 없음)',
    direct_link_tooltip: '다음을 지원합니다:<br>• Facebook 동영상 링크<br>• Instagram 동영상 링크<br>• X(구 트위터) 동영상 링크<br>• TikTok 동영상 링크<br>• 클라우드 비디오 URL 링크<br>• Dropbox 공유 링크<br>• One Drive 공유 링크<br>• Google Drive 공유 링크<br><br>',
    auto_extent_subtitle: '각 자막 당 재생시간을 자동 연장',
    auto_extent_subtitle_tooltip: '아래 주어진 시간(초)만큼 모든 자막의 재생시간을 연장<br/> 만약 연장된 재생시간이 다음 자막과 겹치는 경우, 다음 자막과 겹치지 않도록 다음 자막의 직전까지 연장됩니다. <br/>그로 인해 자막이 비어있는 구간때문에 발생하는 "깜빡임" 현상을 방지하고 자연스럽게 자막이 이어지도록 합니다',
    clipped_video: '오디오 및 비디오 클립 캡처',
    clipped_video_tooltip: '미디어 파일의 선택된 시간 범위 내에서 추출하고 전사합니다.<br>YouTube, mp3, mp4 유형의 미디어를 지원합니다.',
    custom_format: '사용자 정의 형식 내보내기',
    custom_format_tooltip: '아카이브를 TXT 또는 PDF 형식으로 다운로드하도록 선택하면 각 기간, 텍스트, 사람 이름 및 설명의 출력 형식을 개인 필요에 따라 정렬할 수 있습니다. ',
    editing_font_size: '편집 플랫폼의 글꼴 크기 조정',
    low_cost: '저렴한 가격',
    title_basic: '사용한 만큼 결제 플랜',
    basic_cost_usd: '$8',
    basic_cost_ntd: '(240 NTD)',
    hour: 'hour',
    basic_feature5: '이용가능시간 <span style="color: #00d1b2;">60분</span>',
    basic_feature1: '자동 받아쓰기',
    basic_feature2: '40+ 언어 지원 ',
    basic_feature3: '텍스트 편집기',
    basic_feature4: '50+개 이상의 언어로 번역',
    basic_feature7: 'AI 맞춤사전',
    basic_feature6: '상업적 이용 가능',
    basic_feature8: '다국어 자체삽입 자막 지원',
    title_premium: '프리미엄 플랜',
    premium_cost_usd: '$12',
    premium_cost_ntd: '(360 NTD)',
    premium_basic_cost_usd: '$6',
    month: '/월',
    premium_before: '<span class="landing-arrow-left">&#10229;</span>「사용한 만큼 결제」플랜의 모든 기능 포함',
    premium_feature1: '<span style="color: #00d1b2;">120 분</span>의 무료이용시간이 매월 계정에 적립<br><span style="color:#797676;font-size:14px;font-weight: 100;">(남은 이용시간은 이월되며 구독 취소시까지 유효)</span>',
    premium_feature1_tooltip: '만약 매월 적립된 이용시간이 사용되지 않고 남은 경우, 다음 달로 이월되며 구독을 취소하실 때까지 계속해서 유효합니다. <br>매월 적립되는 무료이용시간은 추가로 구매하신 이용시간보다 우선 차감됩니다. <br>추가로 구매하신 이용시간은 구독을 취소하신 후에도 만료되지 않고 유효합니다.',
    premium_feature2: '이용시간 시간당 구매시 25% 할인',
    premium_feature3: '자체자막 동영상 내보내기가 무제한 무료',
    premium_feature4: '받아쓰기 공유 및 동시편집',
    premium_feature5: '음성 녹음 파일을 자막 달린 동영상으로 내보내기 가능',
    premium_feature6: '비디오 클립(자막 자동 조정)',
    title_corp: '엔터프라이즈 플랜',
    bulk_cost_usd: '$69',
    bulk_cost_ntd: '(2070 NTD)',
    bulk_basic_cost_usd: '$4',
    bulk_before: '<span class="landing-arrow-left">&#10229;</span>「사용한 만큼 결제」 및 「프리미엄 플랜」의 모든 기능 포함',
    bulk_feature2: '이용시간 시간당 구매시 63% 할인',
    premium_feature1_bulk: '<span style="color: #00d1b2;">1000 분</span>의 무료이용시간이 매월 계정에 적립<br><span style="color:#797676;font-size:14px;font-weight: 100;">(남은 이용시간은 이월되며 구독 취소시까지 유효)</span>',
    bulk_feature1: '회사명 및 납세자 등록번호가 기재된 영수증 다운로드 가능',
    bulk_feature3: '무료이용시간을 다른 유저와 공유',
    bulk_feature4: '6단계 유저 권한 조정 및 관리',
    go_to_price: '더 알아보기',
    choose_plan: '플랜 선택',
    annually: '연간 결제 시스템',
    monthly: '월별 결제 시스템',
    annually_save: '연회비 {0}% 절약',
    annually_deduct: '${0} 연간 청구',
    api_integration_corp_tooltip: '더 많은 정보가 필요하시면 help@taption.com으로 연락해 주세요.',
}
import FirebaseService from './firebase.service'
import { onAuthStateChanged, getIdTokenResult,signInWithEmailLink,signOut, applyActionCode, checkActionCode, confirmPasswordReset, getAuth, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail,createUserWithEmailAndPassword, setPersistence,browserSessionPersistence, EmailAuthProvider,reauthenticateWithCredential, GoogleAuthProvider,signInWithPopup,verifyPasswordResetCode,signInWithRedirect , getRedirectResult } from 'firebase/auth';
import { query, where } from "firebase/firestore";
import axios from 'axios'
const USERS_COLLECTION = 'Users'
const USERS_INFOS_COLLECTION = 'UsersInfos'
const USERS_SETTINGS_COLLECTION = 'UsersSettings'
const USERS_ACTIVITY = 'UsersActivities2'
// const convert = require('amrhextotext')
var IdToken = null
const authEndpoint = process.env.VUE_APP_MODE === 'production' ? 'https://api.taption.com/authentication':'https://api.taptiontest.com/authentication';
const cdnEndpoint = process.env.VUE_APP_MODE === 'production' ? 'https://tapprod.b-cdn.net' : 'https://taptesttemp.b-cdn.net';
const getParameter = require('get-parameter');

export default {
  createIdToken (forceRefresh) {
    return FirebaseService.createIdToken(forceRefresh).then(function (idToken) {
      IdToken = idToken
      return idToken
    })
  },

  onAuthStateChanged(callback){
    return onAuthStateChanged(FirebaseService.getAuth(), callback)
  },

  getIdTokenResult(){
    return getIdTokenResult(FirebaseService.getAuthUser(), true)
  },

  setPersistence () {
    return setPersistence(FirebaseService.getAuthUser(), browserSessionPersistence)
   // return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  },

  verifyPasswordResetCode(actionCode){
    return verifyPasswordResetCode(FirebaseService.getAuth(), actionCode)
  },

  confirmPasswordReset(actionCode, newPassword){
    return confirmPasswordReset(FirebaseService.getAuth(), actionCode, newPassword)
  },
  checkActionCode(actionCode){
    return checkActionCode(FirebaseService.getAuth(), actionCode)
  },
  applyActionCode(actionCode){
    return applyActionCode(FirebaseService.getAuth(), actionCode)
  },
  signInWithEmailLink(email, emailLink){
    return signInWithEmailLink(FirebaseService.getAuth(), email, emailLink)
  },
  
  

  getAuth () {
    return FirebaseService.getAuth()
  },
  getAuthUser () {
    return FirebaseService.getAuthUser()
  },
  
  getPasswordEmailCredential(password){
    return EmailAuthProvider.credential(FirebaseService.getAuthUser().email, password);
  },

  reauthenticateWithCredential(credential){
    return reauthenticateWithCredential(FirebaseService.getAuthUser(), credential)
  },
  getIdToken () {
    if (IdToken) {
      return IdToken
    }
    throw ('Need to create the IdToken.')
  },
  getUserByEmailPromise (email) {
    
    const collectionRef = FirebaseService.getCollectionRef(USERS_COLLECTION);
    return  FirebaseService.getQueryDocsPromise(query(collectionRef, where('email','==', email)));
  },
  getCurrentUserListener(callback) {
    return FirebaseService.getSnapshot(USERS_COLLECTION, FirebaseService.getActiveUserId(), null, null, callback);
  },
  getCurrentUserPromise () { 
    return FirebaseService.getDocPromise(USERS_COLLECTION, FirebaseService.getActiveUserId())
  },
  sendEmailVerification(){
    return sendEmailVerification(FirebaseService.getAuthUser())
  },
  sendPasswordResetEmail(email){
    return sendPasswordResetEmail(FirebaseService.getAuth(), email)
  },
  getUserInfoPromise () {
    return FirebaseService.getDocPromise(USERS_INFOS_COLLECTION, FirebaseService.getActiveUserId())
  },
  getUserSignupPromise (email, password) {
    return createUserWithEmailAndPassword(FirebaseService.getAuth(), email, password)
  },
  getUserLoginPromise (email, password) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
  },
  getUserSettingPromise (userId) {
    return FirebaseService.getDocPromise(USERS_SETTINGS_COLLECTION, userId || FirebaseService.getActiveUserId())
  },
  getUserLabelsPromise (uid) { 
    const userId =  !uid || uid === -1 ? FirebaseService.getActiveUserId() : uid; 

    return FirebaseService.getDocsPromise(USERS_SETTINGS_COLLECTION, userId, 'Labels');
  },
  getCustomUserLabelsPromise (uid) { 
   // const userId =  !uid || uid === -1 ? FirebaseService.getActiveUserId() : uid; 

    return FirebaseService.getDocsPromise(USERS_SETTINGS_COLLECTION, FirebaseService.getActiveUserId(), 'Labels_'+uid);
  },
  setLabelPromise (accountId, label) {

    return FirebaseService.getCloudFunctionPromise('setLabel', { accountId: accountId, label: label});

    // const userId =  !accountId || accountId === -1 ? FirebaseService.getActiveUserId() : accountId; 
    // if(label.value === null){
    //   return FirebaseService.deleteDocPromise(USERS_SETTINGS_COLLECTION,userId,'Labels', label.id )
    // }
    // else{
    //   return FirebaseService.setDocPromise(label, USERS_SETTINGS_COLLECTION, userId, 'Labels', label.id)
    // }
  },
  setCustomLabelPermissionPromise (accountId, label) {
    const adminUserId =  FirebaseService.getActiveUserId(); 
    if(label.value === null){
      return FirebaseService.deleteDocPromise(USERS_SETTINGS_COLLECTION,accountId,'Labels_'+adminUserId, label.key )
    }
    else{
      return FirebaseService.setDocPromise(label, USERS_SETTINGS_COLLECTION, accountId, 'Labels_'+adminUserId, label.key)
    }
  },
  getCustomLabelPermissionPromise (accountId) { 
    const adminUserId =  FirebaseService.getActiveUserId(); 

    return FirebaseService.getDocsPromise(USERS_SETTINGS_COLLECTION, accountId, 'Labels_'+adminUserId);
  },
  getGoogleAuthProvider () {
    return new GoogleAuthProvider()
  },

  // getFacebookAuthProvider () {
  //   return new FacebookAuthProvider()
  // },
  // handleRedirectResult: async function() {
  //   const me = this;
  //   try {

  //     me.isLoginLoading = true;
  //     const result = await UserService.getRedirectResultPromise();
  //     if (result.user) {
  //       // User is signed in
  //       console.log('Google Sign-In successful:', result.user);
        
  //       // Get ID token
  //       const idToken = await result.user.getIdToken();
  //       await UserService.appSignIn('login',idToken);

  //       // Send ID token to server
  //       UserService.navigateToAppLoginPage();
  //     }
  //   } catch (error) {
  //     console.error('Error during Google Sign-In:', error);
  //   }
  //   finally{
  //     me.isLoginLoading = false;
  //   }
  // },

  getRedirectResultPromise(){
    return getRedirectResult(FirebaseService.getAuth());
  },
  
  getSignInWithRedirectPromise (provider) {
    return signInWithRedirect(FirebaseService.getAuth(), provider)
  },
  getSignInWithPopupPromise (provider) {
    return signInWithPopup(FirebaseService.getAuth(), provider)
  },
  navigateToAppLoginPage(){
    const state = getParameter('state');
    let query = ''
    if(state){
      query = '?state=' +state;
    }
    window.location.href = process.env.VUE_APP_MODE === 'production' ? 'https://app.taption.com/home'+ query : 'https://app.taptiontest.com/home' + query;
  },
  checkCookie() {
    return axios.get(authEndpoint, {
        withCredentials: true ,
        params: {
            type: 'check'
        }
    });
  },
  appSignIn(type,idToken){
    return axios.post(authEndpoint, {
      type: type,
      idToken: idToken
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  },
  async readTextFile(path, fileName) {
    const cacheBuster = `?cb=${new Date().getTime()}`;
    const fileUrl = `${cdnEndpoint}/${path}/${fileName}${cacheBuster}`;
  
    try {
      // Fetch the file contents
      const response = await axios.get(fileUrl, {
        responseType: 'text', // Specify that the response type is plain text,
        headers: {
          'Content-Type': 'text/plain'
        }
      });
  
      // Log the content of the file
      //console.log('File Content:', response.data);
      return response.data;
    } catch (error) {
      //console.error('Error fetching the file:', error.message);
      return null;
    }
  },
  // signOut(){
  //   return signOut(FirebaseService.getAuth());
  // },
  async appSignOut(){
    try {
      await axios.get(authEndpoint, {
          withCredentials: true ,
          params: {
              type: 'logout'
          }
      })
     // await signOut(FirebaseService.getAuth());

      //getAuth().getInstance().signOut();
      console.log('Logged out successfully');
      window.location.href = process.env.VUE_APP_MODE === 'production' ? 'https://www.taption.com/login' : 'https://www.taptiontest.com/login';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  },
  // getSignOut(){
  //   try {
  //     const clearCookie = axios.get(authEndpoint, {
  //         withCredentials: true ,
  //         params: {
  //             type: 'logout'
  //         }
  //     })
  //     const appSignOut = signOut(FirebaseService.getAuth());
  //     FirebaseService.getAuth().getInstance().signOut();
  //     console.log('Logged out successfully');
  //     return Promise.all([clearCookie, appSignOut]);
  //   } catch (error) {
  //     console.error('Error during logout:', error);
  //   }
  // },
  getSignOutPromise () {
    return signOut(FirebaseService.getAuth())
  },
  hasUserPromise (email) {
    return FirebaseService.getCloudFunctionPromise('isEmailExist', {email:email })
  },
  hasUserExistPromise (otherUid) {
    return FirebaseService.getCloudFunctionPromise('isUserExist', otherUid)
  },
  getPaymentIntentsPromise (number, savePayment, paymentId, paymentType, creditType) {
    if(creditType === 'ai'){
      return FirebaseService.getCloudFunctionPromise('createPaymentIntents', { numberOfAiCredits: number, savePayment: savePayment, paymentId:paymentId, paymentType:paymentType })
    }
    else{
      return FirebaseService.getCloudFunctionPromise('createPaymentIntents', { numberOfHours: number, savePayment: savePayment, paymentId:paymentId, paymentType:paymentType })
    }
  },
  getSetupIntents () {
    return FirebaseService.getCloudFunctionPromise('createSetupIntents', IdToken)
  },
  // createSubscription (paymentMethodId) {
  //   return FirebaseService.getCloudFunctionPromise('createSubscription', {paymentMethodId : paymentMethodId})
  // },
  createSubscription (premiumInfo) {
    return FirebaseService.getCloudFunctionPromise('createSubscription', {paymentMethodId : premiumInfo.paymentMethodId, extraSlots: premiumInfo.extraSlots, totalSlots:premiumInfo.totalSlots, corpName: premiumInfo.companyName, taxId: premiumInfo.taxId, isAnnual: premiumInfo.isAnnual})
  },
  createCorpSubscription (corpInfo) {
    return FirebaseService.getCloudFunctionPromise('createCorpSubscription', {paymentMethodId : corpInfo.paymentMethodId, extraSlots: corpInfo.extraSlots, totalSlots:corpInfo.totalSlots, corpName: corpInfo.companyName, taxId: corpInfo.taxId, isAnnual: corpInfo.isAnnual})
  },
  createTranslationInvoice (transcriptId, language) {
    return FirebaseService.getCloudFunctionPromise('createTranslationInvoice', {transcriptId : transcriptId, language:language})
  },
  // createAddCreditInvoice (hours) {
  //   return FirebaseService.getCloudFunctionPromise('createAddCreditInvoice', {idToken: IdToken, hours:hours})
  // },
  cancelSubscription (isUpgrade) {
    return FirebaseService.getCloudFunctionPromise('cancelSubscription', {canceled:true, isUpgrade:isUpgrade})
  },
  activateSubscription () {
    return FirebaseService.getCloudFunctionPromise('toggleSubscription', {idToken:IdToken, canceled:false})
  },
  detachPaymentMethod () {
    return FirebaseService.getCloudFunctionPromise('detachPaymentMethod')
  },
  updateSubscriptionPaymentMethod(paymentMethodId){
    return FirebaseService.getCloudFunctionPromise('updatePaymentMethod', paymentMethodId);
  },
  getAllMembers () {
    return FirebaseService.getCloudFunctionPromise('getAllMembers')
  },
  getUserAccounts () {
    return FirebaseService.getCloudFunctionPromise('getUserAccounts')
  },
  inviteMember (emails,role, permissions) {
    return FirebaseService.getCloudFunctionPromise('inviteMember', {emails:emails,role:role, permissions:permissions})
  },
  removeMember (email) {
    return FirebaseService.getCloudFunctionPromise('removeMember', email)
  },
  removeMemberPostProcess (email) {
    return FirebaseService.getCloudFunctionPromise('removeMemberPostProcess', email)
  },
  increaseSlots(numberOfSlots, preview){
    return FirebaseService.getCloudFunctionPromise('increaseSlots', {numberOfSlots:numberOfSlots, preview:!!preview})
  },
  updateUser (fields) {
  //  idToken = idToken || IdToken;
    return FirebaseService.getCloudFunctionPromise('userUpdate', { fields:fields})
  },
  updateCompany (fields) {
    return FirebaseService.getCloudFunctionPromise('companyUpdate', { fields:fields})
  },
  getCompanyInfo(){
    return FirebaseService.getDocPromise('Corps', FirebaseService.getActiveUserId());
},
  logError: function (transcriptId, data) {
    return FirebaseService.logError(transcriptId, data);
  },
  getDictionary(){ // testedxxx
    return FirebaseService.getDocsPromise(USERS_SETTINGS_COLLECTION,FirebaseService.getActiveUserId(),'Dictionary');
  },
//   updateToDictionary(originalText,replacedText, language, transcriptId, category){
//     const me = this;
//     const id = convert.textToHex(originalText);
//     let promise = null;
   
//     try{
//       if(replacedText === null){
//         promise = FirebaseService.deleteDocPromise(USERS_SETTINGS_COLLECTION,FirebaseService.getActiveUserId(),'Dictionary', id );
//       }
//       else{
//         promise = FirebaseService.setDocPromise({transcriptId:transcriptId || '', id:id, key:originalText, value: replacedText, language:language, modified: FirebaseService.getServerTimestamp(), category:category || ''},USERS_SETTINGS_COLLECTION,  FirebaseService.getActiveUserId(), 'Dictionary',id);
//       }
//     }
//     catch(err){
//       me.logError(FirebaseService.getActiveUserId(), 'Update dictionary error:'+err);
//       return null;
//     }

//     return {id:id, key:originalText, value: replacedText};
//   },

  purchaseClicked(){
    return FirebaseService.setDocPromise({clicked: FirebaseService.getServerTimestamp()}, USERS_ACTIVITY, FirebaseService.getActiveUserId())
  },

//   updateActivities(transcript, activities){
//     const currentUtc = moment.utc();
//     var utcTime = ""+(9999999999 - currentUtc.unix())+'_'+activities.action;

//     if(!transcript.id || !activities.action){
//       throw new Error('Missing activities parameters!');
//     }

//     activities.uid = FirebaseService.getActiveUserId();
//     activities.email = FirebaseService.getActiveUserEmail();
//     //activities.modified = FirebaseService.getServerTimestamp();
//     activities.modified = currentUtc.format();
//     activities.duration = transcript.duration || '-1';
//     activities.processType = transcript.processType || '-1';
//     activities.transcriptType = transcript.transcriptType || '-1';
//     activities.jobType = transcript.jobType || '-1';
//     activities.id = transcript.id || '-1';
//     activities.jobType = transcript.jobType || '-1';

//     FirebaseService.setDocPromise({uid:FirebaseService.getActiveUserId(),modified: FirebaseService.getServerTimestamp()}, USERS_ACTIVITY, FirebaseService.getActiveUserId());
//     if(activities.action){
//       FirebaseService.setDocPromise(activities, 'FeatureActivities',  activities.action, FirebaseService.getActiveUserId(), utcTime);
//       FirebaseService.setDocPromise(activities, 'FeatureActivities',  'fields', 'userIds', FirebaseService.getActiveUserId());
//       FirebaseService.setDocPromise(activities, 'FeatureActivities',  'fields', 'transcriptIds', activities.id);
//     }
//     return FirebaseService.setDocPromise(activities, USERS_ACTIVITY,  FirebaseService.getActiveUserId(), 'logs', utcTime);
//   },
  setUserSettingPromise (settings) {
    return FirebaseService.setDocPromise(settings, USERS_SETTINGS_COLLECTION, FirebaseService.getActiveUserId())
  },
  setSystemConfiguration (ip_address) {
    return FirebaseService.getCloudFunctionPromise('systemConfigurationUpdate', ip_address)
  },
  async getUserGeoLanguageCode () {
    //const me = this;

    try{
      let ipAddress = (await axios.get(process.env.VUE_APP_IPGET_URL)).data.ip
      
      console.log(JSON.stringify(ipAddress));
      
      let languageCode = (await axios.get(`http://api.ipstack.com/${ipAddress}?access_key=${process.env.VUE_APP_IPSTACK_API}`)).data.location.languages[0].code;

      if(languageCode.indexOf('en')>-1){
        languageCode = 'en-US';
      }
      return languageCode
    }
    catch{
      return null;
    }
  },
  async getGeoLocation(){
    try{
     var result =await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.VUE_APP_IPGEO_API}`);
      
     return result.data;
    }
    catch{
      return null;
    }
  },
  async updateLastSeen(){
    let ipAddress = window.localStorage.getItem('ipAddress') || 0;
    if(!ipAddress){
      try{
        ipAddress = (await axios.get(process.env.VUE_APP_IPGET_URL)).data.ip
        window.localStorage.setItem('ipAddress',ipAddress);
        await this.updateUser({ip_address: ipAddress})
      }
      catch{
        console.log('');
      }
    }
   // await this.updateUser({ip_address: ipAddress})

    return ipAddress;
  },
  applyCoupon(couponCode) {
    return FirebaseService.getCloudFunctionPromise('addCoupon', { couponCode:couponCode})
  }
}

module.exports = {
  update: 'Mettre à jour',
  cancel: 'Annuler',
  remove: 'Supprimer',
  session_title: 'Votre session est sur le point d\'expirer',
  session_description: 'Vous serez déconnecté dans 5 minutes',
  session_ok: 'Rester connecté',
  session_no: 'Me déconnecter',
  free_min_title: 'Félicitations !',
  free_min_desc: 'Vous avez reçu <span style="color:#2fc59f!important">{0} minutes</span> grâce à {1} nouvelle(s) recommandation(s).',
  email_placeholder: 'Saisissez une adresse e-mail...',
  a_few_seconds_ago:'il y a quelques secondes',
  short_seconds:'s',
  short_minutes:'min',
  minute:'minute',
  minutes:'minutes',
  hour:'heure',
  hours:'heures',
  day:'jour',
  days:'jours',
  month:'mois',
  months:'mois',
  year:'an',
  years:'ans',
  ago:'il y a',
  a:'un',
  an:'une',
  title: 'Titre',
  welcome: 'Bienvenue',
  youtube: 'YouTube',
  send:'Envoyer',
  on:'Activé',
  off:'Désactivé',
  new: 'Nouveau',
  search: 'Rechercher',
  upgrade: 'Mettre à niveau',

  feature_updating: 'Fonctionnalité en cours de mise à jour, revenez plus tard.',

  copy: 'Copier',
  copied: 'Copié',
  trial:'Essai',
  basic:'Basique',
  premium:'Premium',
  corp:'Entreprise',

  speakers:'intervenants',
  captions:'sous-titres',
  article:'article',
  char:'caractère',
  
  language:'Langue',
  duration:'Durée',
  type:'Type',
  date: 'Date',

  transcriptType1:'Sous-titre',
  transcriptType2:'Intervenant',
  transcriptType3:'Article',
  accounts:'Comptes',
  label:'Étiquette',
  none: 'Aucun',
  me: 'Moi',
  usd:'',
  january:'Janvier',
  february:'Février',
  march:'Mars',
  april:'Avril',
  may:'Mai',
  june:'Juin',
  july:'Juillet',
  august:'Août',
  september:'Septembre',
  october:'Octobre',
  november:'Novembre',
  december:'Décembre',
  th:'',
  pricing:'Tarifs',
  privacy:'Confidentialité',
  terms:'Conditions',
  blog:'Ressources',
  video:'Chaîne YouTube',
  cookieSettings:'Paramètres des cookies',
  back:'Retour',
  credit:'crédit(s)',
  ok:'Ok'
}
